import React from 'react'
import Page from '../components/Page'
import SEO from '../components/seo'
import About from '../components/About'

const AboutPage = () => (
  <Page>
    <SEO
      title="Why Opentrons Exists | Open-Source Lab Automation $5,000+"
      description="Lab automation should be affordable, easy to use, and open to customization. The more people that have access to powerful technology, the better."
    />
    <About />
  </Page>
)

export default AboutPage
