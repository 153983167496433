/* eslint-disable react/no-unescaped-entities */
// @ flow

import * as React from 'react'
import {Link} from 'gatsby'

import {ContentHalf, LinkOut, Icon, LightBox} from '../../ui-components'
import TwitterFeed from './TwitterFeed'

import WILL from './assets/will.jpg'
import KRISTEN from './assets/kristen.jpg'

import {ICON_DATA, OPEN_SOURCE_VIDEO, LAB_ROBOT_VIDEO} from './about-data'

import * as styles from './About.module.css'

export default function About() {
  const [videoOpen, setVideoOpen] = React.useState(false)
  const [videoSrc, setVideoSrc] = React.useState('')
  const openVideo = src => {
    if (src) {
      setVideoSrc(src)
      setVideoOpen(true)
    }
  }
  const width = '24px'
  const height = '24px'
  const VIDEO_ICON = 'video-button'
  return (
    <>
      <div className={styles.container}>
        <h2 className={styles.h2}>About us</h2>
        <div className={styles.about_container}>
          <ContentHalf className={styles.content_half}>
            <div className={styles.copy_container}>
              <p className={styles.p}>
                Today, biologists spend too much time pipetting by hand. We
                think biologists should have robots to do pipetting for them.
                People doing science should be free of tedious benchwork and
                repetitive stress injuries. They should be able to spend their
                time designing experiments and analyzing data.
              </p>
              <p className={styles.p}>That's why we started Opentrons.</p>
              <p className={styles.p}>
                We make robots for biologists. Our mission is to provide the
                scientific community with a common platform to easily share
                protocols and reproduce each other's results. Our robots
                automate experiments that would otherwise be done by hand,
                allowing our community to spend more time pursuing answers to
                some of the 21st century’s most important questions.
              </p>
            </div>

            <div className={styles.company_links}>
              <Link className={styles.h5} to="/team">
                Meet our team ›
              </Link>
              <a
                className={styles.h5}
                href="https://blog.opentrons.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Check out our blog ›
              </a>
              <Link className={styles.h5} to="/contact">
                Contact Us ›
              </Link>
              <Link className={styles.h5} to="/jobs">
                Join Us ›
              </Link>
            </div>
            <div className={styles.icon_container}>
              {ICON_DATA.map((icon, i) => {
                return (
                  <LinkOut key={i} url={icon.url} gtm={icon.gtm}>
                    <Icon
                      className={styles.icon}
                      iconName={icon.social}
                      width={width}
                      height={height}
                    />
                  </LinkOut>
                )
              })}
            </div>
          </ContentHalf>
          <ContentHalf className={styles.content_half}>
            <div className={styles.twitter_container}>
              <TwitterFeed />
            </div>
          </ContentHalf>
        </div>

        <div className={styles.video_container}>
          <div className={styles.video}>
            <h4 className={styles.h4}>Why Opensource</h4>
            <div
              className={styles.clickable_play}
              onClick={() => openVideo(OPEN_SOURCE_VIDEO)}
            >
              <img src={KRISTEN} alt="" />
              <Icon className={styles.video_button} iconName={VIDEO_ICON} />
            </div>
          </div>
          <div className={styles.video}>
            <h4 className={styles.h4}>Democratizing Lab Robotics</h4>
            <div
              className={styles.clickable_play}
              onClick={() => openVideo(LAB_ROBOT_VIDEO)}
            >
              <img src={WILL} alt="" />
              <Icon className={styles.video_button} iconName={VIDEO_ICON} />
            </div>
          </div>
        </div>
        {videoOpen && (
          <LightBox
            videoSrc={`https://player.vimeo.com/video/${videoSrc}`}
            onCloseClick={() => setVideoOpen(false)}
          />
        )}
      </div>
    </>
  )
}
