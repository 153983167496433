import React from 'react'
import Helmet from 'react-helmet'

import {TWITTER_FEED_LINK} from './about-data'

export default function TwitterFeed() {
  return (
    <>
      <a
        className="twitter-timeline"
        data-width="100%"
        data-height="100%"
        data-theme="light"
        data-chrome="noscroll"
        href={TWITTER_FEED_LINK}
      />
      <Helmet>
        <script
          id="twitter-wjs"
          type="text/javascript"
          async
          defer
          src="//platform.twitter.com/widgets.js"
        />
      </Helmet>
    </>
  )
}
