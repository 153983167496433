// extracted by mini-css-extract-plugin
export var container = "About-module--container--Cqv3y";
export var h2 = "About-module--h2--qaCv8";
export var content_third = "About-module--content_third--G9ZW+";
export var h4 = "About-module--h4--5eoez";
export var company_links = "About-module--company_links--UQ7mr";
export var h5 = "About-module--h5--16eQz";
export var p = "About-module--p--pCGC7";
export var content_half = "About-module--content_half--Oe0cQ";
export var about_container = "About-module--about_container--b34n5";
export var copy_container = "About-module--copy_container--ZjInU";
export var icon_container = "About-module--icon_container---3Hqx";
export var clickable_play = "About-module--clickable_play--5LqKa";
export var icon = "About-module--icon--JSA3U";
export var light_bg = "About-module--light_bg--WBfRC";
export var message_container = "About-module--message_container--nfzGX";
export var video = "About-module--video--+EVWV";
export var video_button = "About-module--video_button--mZNDJ";
export var video_container = "About-module--video_container--W4cWz";
export var twitter_container = "About-module--twitter_container--FG7Ti";