export const ICON_DATA = [
  {
    social: 'facebook-gray',
    url: 'https://www.facebook.com/opentronslabworks/',
    gtm: {
      action: 'click',
      category: 'b-app',
      label: 'api-docs-button',
    },
  },
  {
    social: 'instagram-gray',
    url: 'https://www.instagram.com/opentrons_/?utm_source=ig_embed&hl=en',
    gtm: {
      action: 'click',
      category: 'b-app',
      label: 'api-docs-button',
    },
  },
  {
    social: 'youtube-gray',
    url: 'https://www.youtube.com/channel/UCvMRmXIxnHs3AutkVhuqaQg',
    gtm: {
      action: 'click',
      category: 'b-app',
      label: 'api-docs-button',
    },
  },
  {
    social: 'twitter-gray',
    url: 'https://twitter.com/opentrons?lang=en',
    gtm: {
      action: 'click',
      category: 'b-app',
      label: 'api-docs-button',
    },
  },
]

export const OPEN_SOURCE_VIDEO = '260834826'
export const LAB_ROBOT_VIDEO = '260831822'

export const TWITTER_FEED_LINK =
  'https://twitter.com/opentrons?ref_src=twsrc%5Etfw'
